export default {
  "sections": "crV",
  "actions": "crL",
  "search-input": "crT",
  "no-upload": "crz",
  "new-link": "cra body-2",
  "between-links": "crH",
  "action-bar": "crO",
  "empty-state": "crm"
};
