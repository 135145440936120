export default {
  "card-details-container": "cAK",
  "title": "cAG caption-bold mb-16",
  "section": "cAr",
  "divider": "cAb",
  "top-divider": "cAy",
  "content": "cAU body-2",
  "description": "cAj",
  "justify-end": "cAW",
  "icon": "cAu ml-8"
};
