export default {
  "row": "cNT body-1",
  "active": "cNz",
  "cell": "cNa",
  "empty": "cNH",
  "ellipsis": "cNO",
  "text-secondary": "cNm",
  "transfer-type": "cNp",
  "transfer-type__icon": "cNx",
  "quick-actions": "cNw",
  "amount": "cNN",
  "cell-approve": "cNv",
  "account-select": "cNo",
  "row-compact": "cNn",
  "cell-amount": "cNi",
  "cell-content-receipt": "cNA body-1",
  "cell-date": "cNY",
  "row-sidebar": "cNh",
  "hidden": "cNf",
  "animated": "cNK",
  "fadein-item": "cNG",
  "animated-cell": "cNr",
  "note": "cNb",
  "checkbox": "cNy"
};
