export default {
  "card": "cTv",
  "error": "cTo",
  "customer-info": "cTn",
  "edit-customer-tooltip": "cTi",
  "title": "cTA",
  "flex-row": "cTY",
  "dot-separator": "cTh",
  "flex-span": "cTf",
  "faq-link": "cTK",
  "tooltip-wrapper": "cTG",
  "badge-union": "cTr"
};
