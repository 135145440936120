export default {
  "attachment-viewer": "cvZ",
  "hide-sidebar": "cvR",
  "sidebar": "cvQ",
  "header": "cve",
  "header-top": "cvB",
  "header-icon": "cvE",
  "header-amount": "cvd",
  "infobox": "cvI",
  "vat": "cvt",
  "validation": "cvM"
};
