export default {
  "section-container": "coE",
  "disclaimer-section": "cod",
  "trip-title": "coI caption-bold mb-16",
  "google-link-container": "cot",
  "link-icon": "coM",
  "trip-details": "coP body-2",
  "trip-details-icon": "col",
  "trip-details-title": "coX",
  "subtitle": "coC caption-bold mb-16",
  "disclaimer": "cok",
  "visualizer": "coJ"
};
