export default {
  "referral-invite-new": "cpm",
  "referral": "cpp",
  "center": "cpx",
  "illustration": "cpw",
  "share-referral": "cpN",
  "choice": "cpv",
  "line": "cpo",
  "email": "cpn",
  "email-input": "cpi",
  "input-field--error": "cpA",
  "error-message": "cpY",
  "external-link-icon": "cph"
};
