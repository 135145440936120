export default {
  "gap-32": "czS",
  "fieldset": "czc",
  "date-picker": "czq",
  "with-performance-date": "czZ",
  "mt-4": "czR",
  "clients": "czQ",
  "dropdown-option": "cze",
  "dropdown-content": "czB",
  "select-client": "czE",
  "client-option": "czd",
  "icon-tooltip": "czI",
  "payment-details": "czt",
  "dates-container": "czM",
  "automatic-number-label": "czP",
  "label": "czl",
  "numbering-container": "czX",
  "fix-width": "czC",
  "customer-error": "czk",
  "amount-text": "czJ",
  "header-text-field-container": "czs",
  "header-text-field": "czF",
  "header-text-field-close-button": "czg",
  "mt-16": "czD"
};
