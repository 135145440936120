export default {
  "mileage-validation": "cNE",
  "actions": "cNd",
  "action-btn": "cNI",
  "action-btn-success": "cNt",
  "action-btn-right": "cNM",
  "request-mileage-validation": "cNP",
  "account-selector": "cNl",
  "has-error": "cNX",
  "funds-disclaimer": "cNC",
  "account-options": "cNk",
  "account-balance": "cNJ",
  "warnings": "cNs"
};
