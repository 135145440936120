/* import __COLOCATED_TEMPLATE__ from './products-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge, Disclaimer } from '@repo/design-system-kit';
import { TrackedArray } from 'tracked-built-ins';

import CURRENCIES from 'qonto/constants/currencies.ts';
import { DEFAULT_VAT_RATE_PER_COUNTRY } from 'qonto/constants/receivable-invoice.ts';
import type ReceivableInvoiceItemModel from 'qonto/models/receivable-invoice/item.ts';
import type ReceivableInvoiceSectionModel from 'qonto/models/receivable-invoice/section.ts';
import ReceivableInvoiceUploadModel from 'qonto/models/receivable-invoices-upload';
import { ProductsAndServices } from 'qonto/react/components/receivable-invoices/form/product-and-services';
import { ProductsForm } from 'qonto/react/receivable-invoices/components/invoice-form/products-form';
import {
  type Feature,
  FEATURES,
} from 'qonto/react/receivable-invoices/components/promotions/ar-features-promo-popup';

interface ReceivableInvoicesProductsModalSignature {
  // The arguments accepted by the component
  Args: {
    invoice: Record<string, any>;
    showPopup: (feature: Feature) => void;
    attachment: Record<string, any>;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesProductsModalComponent extends Component<ReceivableInvoicesProductsModalSignature> {
  @service declare abilities: Services['abilities'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service
  declare accountReceivableDocumentsUploadManager: Services['accountReceivableDocumentsUploadManager'];

  @tracked isVatResetDisclaimerDismissed = false;
  @tracked hasExistingAttachment = Boolean(this.args.attachment);

  badge = Badge;
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
  productsForm = ProductsForm;
  productsAndServices = ProductsAndServices;

  attachmentsErrors = new TrackedArray();
  attachments = new TrackedArray();
  uploadOptions = { callEndpoint: false };

  constructor(owner: unknown, args: ReceivableInvoicesProductsModalSignature['Args']) {
    super(owner, args);

    this.accountReceivableDocumentsUploadManager.registerCallback({
      onUploadFinished: this.onUploadFinished,
    });
  }

  get invoice() {
    return this.args.invoice;
  }

  get sections() {
    return this.args.invoice.sections.filter((section: Record<string, any>) => Boolean(section));
  }

  get header() {
    return this.invoice.header;
  }

  get footer() {
    return this.invoice.footer;
  }

  get isDeposit() {
    return this.invoice.isDeposit;
  }

  get saveMessages() {
    return this.invoice.saveCustomMessages ?? false;
  }

  get files() {
    return this.accountReceivableDocumentsUploadManager.files;
  }

  get file() {
    return this.files.at(-1);
  }

  get shouldShowDropzone() {
    return !this.hasExistingAttachment && (this?.file?.hasErrors || !this.file);
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasAvailableFreeTrial() {
    return (
      this.upsellManager.hasAvailableFreeTrial('ar_advanced_customization') || this.hasInitialTrial
    );
  }

  get badgeType() {
    return this.hasAvailableFreeTrial ? 'tryForFree' : 'upgrade';
  }

  get quoteCurrency() {
    return this.invoice.quote?.get('currency') || CURRENCIES.default;
  }

  get customerCountry() {
    return this.invoice.customer?.get('billingAddress')?.countryCode;
  }

  get showGlobalDiscount() {
    let isDeposit = this.invoice?.get('isDeposit');
    return !isDeposit;
  }

  get isInternationalClient() {
    return (
      Boolean(this.customerCountry) &&
      this.customerCountry !== this.organizationManager.organization.legalCountry
    );
  }

  get shouldDisplayInternationalClientVatDisclaimer() {
    return this.isInternationalClient && !this.isVatResetDisclaimerDismissed;
  }

  get invoicedAmountDisclaimer() {
    return this.intl.t('receivable-invoices.invoice-creation.partially-invoiced.disclaimer', {
      quoteNumber: this.invoice?.quote.get('number'),
      quoteTotalAmount: this.intl.formatNumber(this.invoice?.quote.get('amountDue'), {
        currency: this.quoteCurrency,
        style: 'currency',
      }),
      invoicedAmount: this.intl.formatNumber(this.invoice?.quote.get('invoicedAmount'), {
        currency: this.quoteCurrency,
        style: 'currency',
      }),
      htmlSafe: true,
    });
  }

  @action
  handleHeaderChange(message: string) {
    this.invoice.header = message;
  }

  @action
  handleFooterChange(message: string) {
    this.invoice.footer = message;
  }

  @action
  handleToggleSaveMessages() {
    this.invoice.saveCustomMessages = !this.saveMessages;
  }

  @action
  handleAttachmentUpload(file: File) {
    if (this.abilities.cannot('use accountsReceivableAdvancedCustomization')) {
      this.showAttachmentsPopup();
      return;
    }

    // @ts-expect-error -- missing declaration in fileQueue
    this.accountReceivableDocumentsUploadManager.queue.add(file);
    this.attachmentsErrors = new TrackedArray();
  }

  @action
  onUploadFinished(record: ReceivableInvoiceUploadModel) {
    this.invoice.set('receivableInvoicingUpload', record);
  }

  @action
  handleRemoveFile() {
    this.attachments = new TrackedArray();
    this.accountReceivableDocumentsUploadManager.resetFiles();
    this.invoice.set('receivableInvoicingUpload', null);
  }

  @action
  handleRemoveExistingAttachment() {
    this.args.attachment.unloadRecord();
    this.invoice.set('receivableInvoicingUpload', null);
    this.hasExistingAttachment = false;
  }

  @action
  showAttachmentsPopup() {
    this.segment.track('invoice-attachment_add', { availability: 'not-available' });
    this.args.showPopup(FEATURES.ATTACHMENTS);
  }

  @action
  showSectionsPopup() {
    this.segment.track('item_add-new-section', { availability: 'not-available' });
    this.args.showPopup(FEATURES.SECTIONS);
  }

  @action
  handleVatResetDisclaimerDismiss() {
    this.isVatResetDisclaimerDismissed = true;
  }

  @action
  handleAddItem(section?: ReceivableInvoiceSectionModel, skipTrack?: boolean) {
    const sectionToUpdate = section ?? this.invoice.sections[0];

    if (sectionToUpdate) {
      // Close every other item
      sectionToUpdate.items.forEach((item: ReceivableInvoiceItemModel) => {
        item.isOpen = false;
      });

      sectionToUpdate.items.createRecord({
        isOpen: true,
        vatRate:
          sectionToUpdate.items[0]?.vatRate ??
          DEFAULT_VAT_RATE_PER_COUNTRY[
            this.organizationManager.organization
              .legalCountry as keyof typeof DEFAULT_VAT_RATE_PER_COUNTRY
          ],
        vatExemptionCode: sectionToUpdate.items[0]?.vatExemptionCode,
      });

      // fill the current new item unit with the last item's unit
      const currentIndex = sectionToUpdate.items.length - 1;
      const currentItem = sectionToUpdate.items[currentIndex];
      if (currentItem) {
        currentItem.unit = sectionToUpdate.items[currentIndex - 1]?.unit;

        let customerCountry = this.invoice.customer?.get('billingAddress')?.countryCode;

        if (
          customerCountry &&
          customerCountry !== this.organizationManager.organization.legalCountry
        ) {
          currentItem.resetVat();
        }
      }

      sectionToUpdate.items.setObjects([...sectionToUpdate.items]);
      this.invoice.sections.setObjects([...this.invoice.sections]);

      if (!skipTrack) {
        this.segment.track('item_add-new-item');
      }
    }
  }

  @action
  handleSortItems(section: ReceivableInvoiceSectionModel, sortedIds: string[]) {
    const itemsCopy: ReceivableInvoiceItemModel[] = section.items.slice();
    itemsCopy.sort((a, b) => sortedIds.indexOf(a.uuid) - sortedIds.indexOf(b.uuid));
    section.items.setObjects(itemsCopy);

    this.invoice.sections.setObjects([...this.invoice.sections]);
    this.segment.track('items_reorder');
  }

  @action
  handleDeleteItem(item: ReceivableInvoiceItemModel) {
    // @ts-expect-error -- Not sure what is going here, it's a property not a method
    if (item.isNew) {
      item.deleteRecord();
    } else {
      item.unloadRecord();
    }

    this.invoice.sections.setObjects([...this.invoice.sections]);
  }

  @action
  handleAddSection() {
    if (this.invoice.sections.length === 1 && this.invoice.sections[0].isDefault) {
      this.invoice.sections[0].isDefault = false;
      // Close section items when converting to non-default section
      this.invoice.sections[0].items.forEach((item: ReceivableInvoiceItemModel) => {
        item.isOpen = false;
      });
      this.invoice.sections[0].items.setObjects([...this.invoice.sections[0].items]);
    } else {
      const section = this.invoice.sections.createRecord({ currency: this.invoice.currency });
      this.handleAddItem(section, true);
    }
    this.segment.track('item_add-new-section', {
      availability: 'included',
    });
  }

  @action
  handleDeleteSection(section: ReceivableInvoiceSectionModel) {
    const sections: ReceivableInvoiceSectionModel[] = this.invoice.sections.slice();
    if (sections.length > 1) {
      const sectionIndex = sections.indexOf(section);
      if (sectionIndex === 0) {
        sections[1]?.items.unshift(...section.items);
      } else {
        sections[sectionIndex - 1]?.items.push(...section.items);
      }
      section.unloadRecord();
    } else {
      section.isDefault = true;
    }

    this.segment.track('item_remove-section');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::ProductsModal': typeof ReceivableInvoicesProductsModalComponent;
  }
}
