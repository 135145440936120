export default {
  "row": "cGX",
  "active": "cGC",
  "animated": "cGk",
  "fadein-item": "cGJ",
  "cell": "cGs body-2",
  "cell-content": "cGF",
  "subtitle": "cGg caption",
  "no-padding": "cGD",
  "note": "cGV",
  "cell-amount": "cGL",
  "cell-content-amount": "cGT body-1"
};
