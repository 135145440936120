export default {
  "header-cell": "cpR",
  "empty": "cpQ",
  "header-content": "cpe caption-bold",
  "active": "cpB",
  "align-right": "cpE",
  "col-8": "cpd",
  "col-7": "cpI",
  "col-5": "cpt",
  "col-4": "cpM"
};
