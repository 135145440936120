export default {
  "row": "cyI",
  "row-active": "cyt",
  "avatar": "cyM",
  "cell": "cyP",
  "cell-content": "cyl",
  "cell-counterparty": "cyX",
  "counterparty-name": "cyC",
  "cell-amount": "cyk",
  "status-avatar": "cyJ",
  "counterparty": "cys",
  "creditor-name": "cyF",
  "reference": "cyg",
  "counterparty-details": "cyD",
  "cell-quick-actions": "cyV",
  "quick-actions": "cyL",
  "account-selector": "cyT"
};
