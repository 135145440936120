export default {
  "request-transfer-validation": "cbE",
  "account-selector": "cbd",
  "has-error": "cbI",
  "actions": "cbt",
  "action-btn": "cbM",
  "warnings": "cbP",
  "top-border": "cbl",
  "account-selector-subtitle": "cbX",
  "action-btn-right": "cbC",
  "funds-disclaimer": "cbk",
  "account-options": "cbJ",
  "account-balance": "cbs",
  "request-multi-transfer-validation": "cbF",
  "wrapper": "cbg",
  "warning-message-row": "cbD",
  "multi-transfer-align": "cbV",
  "button-approve": "cbL",
  "button-approve-spinner": "cbT",
  "button-decline": "cbz"
};
