export default {
  "credit-note-sidebar": "cTd",
  "body": "cTI",
  "box": "cTt",
  "box-header": "cTM",
  "footer": "cTP",
  "footer-button": "cTl",
  "sidebar-box": "cTX",
  "border-top": "cTC",
  "row": "cTk",
  "related-invoice-title": "cTJ caption-bold",
  "einvoice-rejected": "cTs",
  "related-documents": "cTF"
};
