export default {
  "container": "czH",
  "depositAmount": "czO",
  "flexRow": "czm",
  "depositInput": "czp",
  "dropdown": "czx",
  "itemRow": "czw",
  "line": "czN",
  "estimates-container": "czv",
  "estimates": "czo",
  "dt": "czn",
  "dd": "czi"
};
