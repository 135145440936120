export default {
  "content": "ciD",
  "balance": "ciV",
  "duration": "ciL",
  "green-text": "ciT",
  "balance-subtitle": "ciz",
  "balance-duration": "cia",
  "footer": "ciH",
  "progress": "ciO",
  "progress-bar": "cim",
  "progress-bar-text": "cip",
  "progress-active": "cix"
};
