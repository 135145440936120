export default {
  "referral-invite": "cxB",
  "referral": "cxE",
  "illustration": "cxd",
  "steps": "cxI",
  "step": "cxt",
  "share-referral": "cxM",
  "choice": "cxP",
  "line": "cxl",
  "email": "cxX",
  "email-input": "cxC",
  "input-field--error": "cxk",
  "error-message": "cxJ",
  "external-link-icon": "cxs"
};
