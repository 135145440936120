export default {
  "header-cell": "cKG",
  "header-content": "cKr caption-bold",
  "active": "cKb",
  "empty": "cKy",
  "request-type": "cKU",
  "status": "cKj",
  "note": "cKW",
  "amount": "cKu"
};
