export default {
  "wrapper": "cij",
  "request-header": "ciW",
  "request": "ciu",
  "scheduled": "cAS",
  "scheduled-label": "cAc",
  "note": "cAq",
  "note-label": "cAZ",
  "note-label-danger": "cAR",
  "note-content": "cAQ",
  "status": "cAe",
  "transfer-amount": "cAB"
};
