export default {
  "row": "cyz",
  "cell": "cya",
  "checkbox": "cyH",
  "icon": "cyO",
  "creditor-item-container": "cym",
  "actions-item-container": "cyp",
  "due-date": "cyx",
  "amount": "cyw",
  "align-right": "cyN"
};
