export default {
  "iban": "crB",
  "checkbox": "crE",
  "toggle-content": "crd",
  "beneficiary-name": "crI",
  "category": "crt",
  "beneficiary-email": "crM",
  "beneficiary-form": "crP",
  "beneficiary-email-container": "crl"
};
