export default {
  "row": "cpX",
  "cell": "cpC",
  "greyed-out": "cpk",
  "struck-through": "cpJ",
  "flex-cell": "cps",
  "details-container": "cpF",
  "overdue": "cpg",
  "align-right": "cpD",
  "customer-name-container": "cpV",
  "icon": "cpL",
  "warning": "cpT",
  "with-separator": "cpz",
  "align-center": "cpa"
};
