export default {
  "attachment-viewer": "cYB",
  "hide-sidebar": "cYE",
  "sidebar": "cYd",
  "header": "cYI",
  "header-top": "cYt",
  "header-icon": "cYM",
  "header-amount": "cYP",
  "infobox": "cYl",
  "vat": "cYX",
  "validation": "cYC"
};
