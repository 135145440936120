export default {
  "wrapper": "cry",
  "header": "crU mb-48",
  "form-title": "crj title-2 mb-16",
  "form-subtitle": "crW body-2",
  "form": "cru",
  "section": "cbS mb-48",
  "section-title": "cbc title-3 mb-16",
  "form-footer": "cbq",
  "footer-btn": "cbZ",
  "transfer-form": "cbR"
};
