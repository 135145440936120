export default {
  "wrapper": "crp",
  "beneficiary": "crx",
  "name": "crw body-1",
  "beneficiary-email": "crN body-2",
  "dropdown-icon": "crv",
  "trusted": "cro",
  "account-number": "crn flex body-2",
  "account-icon": "cri",
  "m-chip": "crA",
  "dropdown": "crY body-2",
  "delete-option": "crh",
  "beneficiary-name": "crf"
};
