export default {
  "section-container": "cwV",
  "disclaimer-section": "cwL",
  "trip-title": "cwT caption-bold mb-16",
  "google-link-container": "cwz",
  "link-icon": "cwa",
  "trip-details": "cwH body-2",
  "trip-details-icon": "cwO",
  "trip-details-title": "cwm",
  "subtitle": "cwp caption-bold mb-16",
  "disclaimer": "cwx",
  "visualizer": "cww"
};
