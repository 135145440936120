export default {
  "payment-details": "can",
  "label-payment-conditions": "cai",
  "dropdown-content": "caA",
  "dropdown-option": "caY",
  "activate-sdd-container": "cah",
  "activate-sdd-header": "caf",
  "selector-toggle-sdd": "caK",
  "selector-toggle-sdd-subtitle": "caG",
  "selector-toggle-content": "car",
  "selector-toggle": "cab",
  "new-badge": "cay",
  "new-badge-activation": "caU",
  "mt-16": "caj",
  "sdd-mandate-select-loading": "caW"
};
