export default {
  "item": "cmP",
  "details": "cml",
  "flex": "cmX",
  "header": "cmC cmX",
  "flex-no-gap": "cmk cmX",
  "file-name": "cmJ",
  "label": "cms body-2",
  "self-icon": "cmF",
  "with-separator": "cmg",
  "dimmed": "cmD"
};
