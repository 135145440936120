export default {
  "header": "cvs",
  "header-type": "cvF",
  "close": "cvg",
  "close-icon": "cvD",
  "header-infos": "cvV",
  "title": "cvL body-1",
  "declined": "cvT",
  "canceled": "cvz",
  "approved": "cva",
  "pending": "cvH",
  "date": "cvO body-2",
  "container": "cvm",
  "picto": "cvp",
  "picto-status": "cvx",
  "general": "cvw",
  "amount": "cvN",
  "counterparty": "cvv body-2",
  "initiator": "cvo",
  "initiator-avatar": "cvn",
  "avatar-image": "cvi",
  "initiator-id": "cvA",
  "name": "cvY body-2",
  "role": "cvh caption-bold",
  "infobox": "cvf",
  "account-infobox": "cvK cvf",
  "beneficiary-warning": "cvG caption-bold",
  "dates-wrapper": "cvr",
  "dates": "cvb",
  "recurrence": "cvy caption-bold",
  "arrow": "cvU",
  "notify-checkbox": "cvj"
};
