export default {
  "header": "cYV",
  "header-type": "cYL",
  "close": "cYT",
  "close-icon": "cYz",
  "header-infos": "cYa",
  "title": "cYH body-1",
  "declined": "cYO",
  "canceled": "cYm",
  "approved": "cYp",
  "pending": "cYx",
  "date": "cYw body-2",
  "container": "cYN",
  "picto": "cYv",
  "picto-status": "cYo",
  "general": "cYn",
  "amount": "cYi",
  "counterparty": "cYA body-2",
  "initiator": "cYY",
  "initiator-avatar": "cYh",
  "avatar-image": "cYf",
  "initiator-id": "cYK",
  "name": "cYG body-2",
  "role": "cYr caption-bold",
  "infobox": "cYb",
  "account-infobox": "cYy cYb",
  "beneficiary-warning": "cYU caption-bold",
  "dates-wrapper": "cYj",
  "dates": "cYW",
  "recurrence": "cYu caption-bold",
  "arrow": "chS",
  "notify-checkbox": "chc"
};
