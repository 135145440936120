export default {
  "request-expense-report-validation": "chZ",
  "account-selector": "chR",
  "has-error": "chQ",
  "actions": "che",
  "action-btn": "chB",
  "action-btn-success": "chE",
  "warnings": "chd",
  "top-border": "chI",
  "account-selector-subtitle": "cht",
  "action-btn-right": "chM",
  "funds-disclaimer": "chP",
  "account-options": "chl",
  "account-balance": "chX"
};
