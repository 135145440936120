export default {
  "row": "cGS",
  "active": "cGc",
  "dot": "cGq",
  "red": "cGZ",
  "green": "cGR",
  "yellow": "cGQ",
  "status": "cGe",
  "align-right": "cGB",
  "animated": "cGE",
  "fadein-item": "cGd",
  "cell": "cGI body-2",
  "cell-content": "cGt",
  "amount": "cGM body-1",
  "subtitle": "cGP caption",
  "no-padding": "cGl"
};
