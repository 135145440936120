export default {
  "form": "cOU",
  "container": "cOj",
  "section": "cOW",
  "footer": "cOu",
  "attachment": "cmS",
  "attachment-title": "cmc",
  "attachment-uploader-disabled": "cmq",
  "attachment-overlay": "cmZ",
  "attachment-overlay-hidden": "cmR"
};
