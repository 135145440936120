export default {
  "row": "cxr",
  "active": "cxb",
  "animated": "cxy",
  "fadein-item": "cxU",
  "cell": "cxj",
  "cell-content": "cxW",
  "row-sidebar": "cxu",
  "empty": "cwS",
  "cell-amount": "cwc",
  "subtitle": "cwq caption",
  "hidden": "cwZ",
  "cell-approve": "cwR",
  "quick-actions": "cwQ",
  "account-select": "cwe",
  "note": "cwB",
  "cell-content-amount": "cwE body-1",
  "cell-status": "cwd"
};
