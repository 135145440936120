export default {
  "container": "cON",
  "main": "cOv",
  "preview": "cOo",
  "form": "cOn",
  "scrollable": "cOi",
  "header": "cOA title-1",
  "form-sections": "cOY",
  "form-section": "cOh",
  "footer": "cOf",
  "preview-header": "cOK",
  "centered": "cOG",
  "preview-content-wrapper": "cOr",
  "preview-content": "cOb",
  "payment-link-preview": "cOy"
};
