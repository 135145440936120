export default {
  "numbering-container": "czW",
  "flex-row": "czu",
  "automatic-number-label": "caS",
  "icon-tooltip": "cac",
  "fix-width": "caq",
  "dates-container": "caZ",
  "with-performance-date": "caR",
  "date-picker": "caQ",
  "label": "cae",
  "header-text-field-container": "caB",
  "mt-16": "caE",
  "header-text-field": "cad",
  "header-text-field-close-button": "caI",
  "deposit-toggle-container": "cat"
};
