export default {
  "subtitle": "ciI",
  "green-text": "cit",
  "balance-subtitle": "ciM",
  "options": "ciP",
  "shadow": "cil",
  "menu-item": "ciX body-2",
  "close-account": "ciC",
  "footer": "cik",
  "progress": "ciJ",
  "progress-bar": "cis",
  "progress-bar-text": "ciF",
  "progress-active": "cig"
};
