export default {
  "qonto-pilot-modal": "cVU",
  "content": "cVj",
  "header": "cVW",
  "transition-state-container": "cVu",
  "lottie": "cLS",
  "prompt-input": "cLc",
  "buttons": "cLq",
  "footer": "cLZ caption",
  "divider": "cLR",
  "illustration": "cLQ",
  "icon": "cLe",
  "quick-actions-container": "cLB",
  "action": "cLE body-2",
  "action-title": "cLd",
  "disabled": "cLI",
  "action-tag": "cLt caption-bold",
  "hotkey": "cLM body-1",
  "footer-action": "cLP",
  "grow": "cLl",
  "footer-feedback-link": "cLX",
  "buttons-container": "cLC",
  "transfer-details-container": "cLk",
  "error-container": "cLJ",
  "option-iban": "cLs",
  "actions": "cLF",
  "title": "cLg",
  "group-title": "cLD caption-bold",
  "beta-badge": "cLV",
  "hotkeys": "cLL",
  "supplier-invoices-dropzone": "cLT",
  "success-icon": "cLz",
  "error-icon": "cLa",
  "file-items": "cLH",
  "file-item": "cLO",
  "uploader-file": "cLm",
  "transactions-table": "cLp",
  "transaction": "cLx",
  "prompt-example": "cLw"
};
