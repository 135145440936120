export default {
  "container": "cOt",
  "form-container": "cOM",
  "content": "cOP",
  "fields": "cOl",
  "input-settings": "cOX",
  "current-year": "cOC",
  "next-invoice-number": "cOk",
  "preview-container": "cOJ",
  "actions": "cOs",
  "close-button": "cOF btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cOg",
  "preview-image": "cOD"
};
