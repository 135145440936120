export default {
  "sidebar": "cHi",
  "body": "cHA",
  "form": "cHY",
  "form-section": "cHh",
  "form-title": "cHf title2",
  "row": "cHK",
  "payment-details": "cHG",
  "col": "cHr",
  "footer": "cHb",
  "footer-button": "cHy"
};
