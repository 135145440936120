export default {
  "modal": "cmQ",
  "action-container": "cme",
  "similar-label": "cmB",
  "info-icon": "cmE",
  "container": "cmd",
  "subtitle": "cmI caption-bold",
  "error": "cmt",
  "item-container": "cmM"
};
