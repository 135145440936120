export default {
  "wrapper": "cpf",
  "tabs": "cpK",
  "tabs-nav": "cpG",
  "tabs-panel": "cpr",
  "tabs-panel-wrapper": "cpb",
  "list-head": "cpy",
  "list": "cpU",
  "footer": "cpj",
  "reward-amount": "cpW",
  "rewarded": "cpu",
  "registered": "cxS",
  "disclaimer": "cxc",
  "empty-state-wrapper": "cxq",
  "empty-state": "cxZ",
  "illustration": "cxR",
  "spinner": "cxQ",
  "total-earned": "cxe"
};
