export default {
  "row": "cns",
  "active": "cnF",
  "dot": "cng",
  "red": "cnD",
  "green": "cnV",
  "yellow": "cnL",
  "status": "cnT",
  "align-right": "cnz",
  "animated": "cna",
  "fadein-item": "cnH",
  "cell": "cnO body-2",
  "cell-content": "cnm",
  "amount": "cnp body-2",
  "subtitle": "cnx caption",
  "padding-left": "cnw",
  "padding-right": "cnN",
  "note": "cnv"
};
