export default {
  "row": "cAT",
  "empty": "cAz",
  "active": "cAa",
  "animated": "cAH",
  "fadein-item": "cAO",
  "cell": "cAm body-2",
  "cell-content": "cAp",
  "cell-amount": "cAx",
  "cell-content-amount": "cAw body-1"
};
