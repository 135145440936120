export default {
  "header": "cfk",
  "header-type": "cfJ",
  "close": "cfs",
  "close-icon": "cfF",
  "header-infos": "cfg",
  "title": "cfD body-1",
  "declined": "cfV",
  "canceled": "cfL",
  "approved": "cfT",
  "date": "cfz body-2",
  "container": "cfa",
  "picto": "cfH",
  "request": "cfO",
  "picto-status": "cfm",
  "check": "cfp",
  "stop": "cfx",
  "warning": "cfw",
  "general": "cfN",
  "amount": "cfv",
  "counterparty": "cfo body-2",
  "initiator": "cfn",
  "initiator-avatar": "cfi",
  "avatar-image": "cfA",
  "initiator-id": "cfY",
  "name": "cfh body-2",
  "role": "cff caption-bold",
  "infobox": "cfK",
  "account-infobox": "cfG cfK",
  "beneficiary-warning": "cfr caption-bold",
  "dates-wrapper": "cfb",
  "dates": "cfy",
  "recurrence": "cfU caption-bold",
  "arrow": "cfj",
  "notify-checkbox": "cfW"
};
