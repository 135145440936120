export default {
  "client-form": "cLG",
  "align-start": "cLr",
  "kind": "cLb",
  "name-fields": "cLy",
  "field": "cLU",
  "required-data-disclaimer": "cLj",
  "row": "cLW",
  "child": "cLu",
  "confirm-action": "cTS",
  "delete-action": "cTc",
  "legal-info": "cTq",
  "column-layout": "cTZ"
};
