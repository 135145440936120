export default {
  "row": "cGz",
  "active": "cGa",
  "dot": "cGH",
  "red": "cGO",
  "green": "cGm",
  "yellow": "cGp",
  "status": "cGx",
  "align-right": "cGw",
  "animated": "cGN",
  "fadein-item": "cGv",
  "cell": "cGo body-2",
  "cell-content": "cGn",
  "amount": "cGi body-2",
  "subtitle": "cGA caption",
  "padding-left": "cGY",
  "padding-right": "cGh",
  "note": "cGf"
};
