export default {
  "invoice-sidebar": "cHU",
  "body": "cHj",
  "box": "cHW",
  "box-header": "cHu",
  "footer": "cOS",
  "footer-button": "cOc",
  "sidebar-box": "cOq",
  "border-top": "cOZ",
  "row": "cOR",
  "status-container": "cOQ",
  "due-date-container": "cOe",
  "mark-as-btn": "cOB",
  "primary-actions": "cOE cOq",
  "primary-action": "cOd body-1",
  "button-icon": "cOI"
};
