export default {
  "header-cell": "cKO",
  "header-content": "cKm caption-bold",
  "active": "cKp",
  "empty": "cKx",
  "align-right": "cKw",
  "request-type": "cKN",
  "requester": "cKv",
  "amount": "cKo"
};
