export default {
  "cell-content": "cAk",
  "icon": "cAJ",
  "status_check-m": "cAs",
  "description": "cAF body-1",
  "from": "cAg body-2",
  "from--abort": "cAD",
  "description--abort": "cAV",
  "title": "cAL body-2"
};
