export default {
  "header": "cwN",
  "header-type": "cwv",
  "close": "cwo",
  "close-icon": "cwn",
  "header-infos": "cwi",
  "title": "cwA body-1",
  "declined": "cwY",
  "canceled": "cwh",
  "approved": "cwf",
  "pending": "cwK",
  "date": "cwG body-2",
  "mileage-icon": "cwr",
  "distance-subtitle": "cwb body-2",
  "link-icon": "cwy",
  "container": "cwU",
  "picto": "cwj",
  "general": "cwW",
  "amount": "cwu",
  "calculation-container": "cNS",
  "counterparty": "cNc body-2",
  "infobox": "cNq"
};
