export default {
  "header-cell": "cne",
  "empty": "cnB",
  "header-content": "cnE caption-bold",
  "active": "cnd",
  "header-type": "cnI",
  "header-reason": "cnt",
  "header-status": "cnM",
  "header-amount": "cnP",
  "row-sidebar": "cnl",
  "hidden": "cnX",
  "animated": "cnC",
  "fadein-item": "cnk",
  "animated-cell": "cnJ"
};
