export default {
  "container": "caM",
  "title-and-trash": "caP",
  "trash": "cal",
  "quantity-amount": "caX",
  "flex-row": "caC",
  "quantity": "cak",
  "quantityInput": "caJ",
  "border-left": "cas",
  "dropdown": "caF",
  "item-row": "cag",
  "unit-price": "caD",
  "price-input": "caV",
  "hidden": "caL",
  "d-none": "caT",
  "dropdown-option": "caz",
  "list-row": "caa",
  "row-title": "caH",
  "row-price": "caO body-2",
  "row-missing-details-container": "cam",
  "row-missing-details-label": "cap body-2",
  "warning-icon": "cax",
  "row-internal-note": "caw",
  "with-internal-note": "caN",
  "dropdown-content": "cav",
  "select-item": "cao"
};
