export default {
  "wrapper": "cxF",
  "tabs": "cxg",
  "tabs-nav": "cxD",
  "tabs-panel-wrapper": "cxV",
  "list-head": "cxL",
  "list": "cxT",
  "footer": "cxz",
  "reward-amount": "cxa",
  "earned": "cxH",
  "pending": "cxO",
  "empty-state-wrapper": "cxm",
  "empty-state": "cxp",
  "illustration": "cxx",
  "error-wrapper": "cxw",
  "error-illustration": "cxN",
  "error-description": "cxv",
  "spinner": "cxo",
  "total-earned": "cxn"
};
