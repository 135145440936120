export default {
  "modal-wrapper": "cmV",
  "spinner-container": "cmL",
  "spinner": "cmT",
  "container": "cmz",
  "tabs-item": "cma",
  "form-container": "cmH",
  "fieldset": "cmO",
  "preview-container": "cmm",
  "switch-container": "cmp",
  "pdf-preview-container": "cmx",
  "form-content": "cmw",
  "button-container-footer": "cmN"
};
