export default {
  "invoice-sidebar": "cHM",
  "body": "cHP",
  "box": "cHl",
  "box-header": "cHX",
  "footer": "cHC",
  "footer-button": "cHk",
  "footer-button-tooltip": "cHJ",
  "reduced-fontsize": "cHs",
  "sidebar-box": "cHF",
  "border-top": "cHg",
  "row": "cHD",
  "status-container": "cHV",
  "due-date-container": "cHL",
  "greyed-out": "cHT",
  "struck-through": "cHz",
  "overdue": "cHa",
  "primary-actions": "cHH cHF",
  "primary-action": "cHO",
  "danger-action": "cHm",
  "button-icon": "cHp",
  "button-label": "cHx body-2",
  "related-credit-note-title": "cHw caption-bold",
  "paid-at-placeholder": "cHN",
  "collapsible-timeline": "cHv",
  "timeline-status-label": "cHo",
  "related-documents": "cHn"
};
