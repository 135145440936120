export default {
  "header": "cos",
  "header-type": "coF",
  "close": "cog",
  "close-icon": "coD",
  "header-infos": "coV",
  "title": "coL body-1",
  "declined": "coT",
  "canceled": "coz",
  "approved": "coa",
  "pending": "coH",
  "date": "coO body-2",
  "mileage-icon": "com",
  "distance-subtitle": "cop body-2",
  "link-icon": "cox",
  "container": "cow",
  "picto": "coN",
  "general": "cov",
  "amount": "coo",
  "calculation-container": "con",
  "counterparty": "coi body-2",
  "infobox": "coA"
};
